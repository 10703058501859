<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-26 16:56:19
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:12
 * @Description: 快递单号输入
 * @FilePath: \src\views\App\Package\ExpressInput.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible="show"
    width="50%"
    height="100%"
    top="5vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="true"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <template>
          <!-- ------------- 输入条 ------------- -->
          <div class="table-search-box">
            <!-- <div class="table-search-title">检索</div> -->
            <div class="table-search-content">
              <div class="table-search-item express-input" style="width: 60%">
                <label>快递单号：</label>
                <el-input
                  size="small"
                  width="80%"
                  ref="express_id_input"
                  v-model="express_id"
                  @keyup.enter.native="_EnterExpress()"
                  suffix-icon="xxxx"
                ></el-input>
              </div>
            </div>
          </div>

          <el-table :data="TableData" border size="small" style="width: 100%">
            <!-- ID -->
            <el-table-column
              label="ID"
              prop="id"
              align="center"
              width="80px"
            ></el-table-column>

            <!-- 快递单号 -->
            <el-table-column
              label="快递单号"
              prop="express_id"
              align="center"
              width="300px"
            >
            </el-table-column>

            <!-- 添加时间 -->
            <el-table-column
              label="添加时间"
              prop="add_time"
              align="center"
              width="200px"
            ></el-table-column>

            <!-- 操作 -->
            <el-table-column label="操作" align="center">
              <template slot-scope="s">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="_RemoveExpress(s.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-divider></el-divider>

          <el-button
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="_Ok"
            >确认</el-button
          >
          <el-button size="small" icon="el-icon-close" @click="_HandleClose"
            >取消</el-button
          >
        </template>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ExpressInput",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    default_list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: "快件输入器", //弹窗标题
      loading: false, //加载控制
      express_id: "", //快递单号
      TableData: [], //表格数据
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.$nextTick(() => {
          //设置焦点
          this.$refs.express_id_input.focus();
        });
        //写入默认值
        let default_list = this.default_list;
        if (typeof default_list === "object" && default_list.length > 0) {
          this.TableData = this.default_list;
        }
      }
    },
  },
  methods: {
    //确认单号输入
    _EnterExpress() {
      let express_id = this.express_id;
      if (express_id === "" || express_id.length < 6) {
        this.$utils.msg("订单号不能为空且不能小于6位!");
        this.express_id = "";
        return;
      }

      //检查单号是否存在
      let index = this.$utils.arrayGetIndex(
        this.TableData,
        "express_id",
        express_id
      );
      if (index !== null) {
        this.express_id = "";
        return this.$utils.msg("快递单号已存在!");
      }

      var time = new Date();
      let year = time.getFullYear(); //获取年份
      let month = time.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let date = time.getDate(); //获取当前日(1-31)
      let hours = time.getHours(); //获取当前小时数(0-23)
      let minutes = time.getMinutes(); //获取当前分钟数(0-59)
      let seconds = time.getSeconds(); //获取当前秒数(0-59)
      if (seconds.length == 1) {
        seconds = "0" + seconds;
      }
      let isTime = year + "-" + month + "-" + date + " ";
      isTime += hours + ":" + minutes + ":" + seconds;
      this.TableData.push({
        id: this.TableData.length + 1, //
        add_time: isTime,
        express_id: express_id,
      });
      this.express_id = "";
    },

    //移除单号
    _RemoveExpress(item) {
      this.$utils.arrayDelete(this.TableData, item); //移除

      //刷新id
      let tableData = this.TableData;
      for (let i = 0; i < tableData.length; i++) {
        this.TableData[i]["id"] = i + 1;
      }
    },

    //完成选择
    _Ok() {
      this.$emit("confirm-select", this.TableData);
      this.$nextTick(() => {
        this._HandleClose();
      });
    },

    //关闭
    _HandleClose() {
      this.$emit("update:show", false); //触发input事件，并传入新值
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style>
.express-input {
  height: auto;
}
.express-input.table-search-item .el-input {
  width: calc(100% - 100px);
  margin-bottom: 12px;
}
</style>
